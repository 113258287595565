<template>
  <div class="page half-width candidate-page">
    <div class="page-content pr-15">
      <v-row class="pa-0 pl-3 mb-0 page-header align-start">
        <v-col cols="5" class="pa-0 page-header_left">
          <div class="page-header_back-link" @click="$router.back()">
            <div class="icon icon-arrow"></div>
            <span>Назад</span>
          </div>
          <v-skeleton-loader v-if="loadingCandidate" type="text" width="320" height="34"/>
          <div v-else class="page-title">
            <v-icon v-if="+candidate.favourite" style="color: gold">mdi-star</v-icon>
            {{ candidate.name }} {{ candidate.surname }}
          </div>

          <v-skeleton-loader
            v-for="i in 3" :key="i"
            class="mt-3 mb-1" v-if="loadingCandidate" type="text" width="180" height="16"/>
          <div class="page-subtitle important-contacts">
            <div v-if="candidate.phone" style="display: inline-flex;">
              <a :href="'tel:'+candidate.phone" class="mb-1">
                <v-icon size="18" class="mr-1" color="primary">mdi-cellphone</v-icon>
                {{ convertPhoneStr(candidate.phone) }}
              </a>
              <a style="display: inline-flex;" target="_blank" :href="`https://wa.clck.bar/${candidate.phone}`" class="mb-1">
                <v-icon size="18" class="ml-2 mr-1" color="primary">mdi-whatsapp</v-icon>
                WhatsApp
              </a>
            </div>
            <a
              v-if="candidate.email"
              :href="'mailto:'+candidate.email">
              <v-icon size="18" color="primary" class="mr-1">mdi-email-outline</v-icon>
              {{candidate.email}}
            </a>
          </div>
        </v-col>
        <v-col cols="3" class="pa-0 popover-container" v-if="!isSharedByToken">
          <PopoverList :control-buttons="actionButtons" :item="candidate" :is-slot="true">
            <template v-slot:activator>
              <v-btn depressed>Действия
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
          </PopoverList>
        </v-col>
        <v-col class="pa-0 page-header_right" style="position: relative;">
          <div class="candidate-avatar">
            <v-skeleton-loader v-if="loadingCandidate" type="image" height="210"/>
            <CandidateMedia v-else parallax :candidate="candidate"/>
            <div
              class="candidate-avatar_rating"
              @click=" candidate.access_type !== 'read' ? $eventBus.emit('not-main-event-modal-open', {
            candidate: candidate.id,
            vacancy: Number(candidate.vacancy?.id) || '',
            title: 'Комментарий с оценкой',
          }) : ()=>{}"
            >
              {{ candidate.rate ? Number(candidate.rate).toFixed(1) : '0.0' }}
              <div v-if="candidate.access_type !== 'read'" class="icon icon-pencil"></div>
            </div>
            <div class="candidate-avatar_contacts"
                 @click="$refs.contacts_modal.open(candidate.id, candidate.phone, false, candidate.access_type)">
              <svg class="icon" width="40" height="40" viewBox="0 0 40 40" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M20 6.15381C12.3569 6.15381 6.15384 11.6945 6.15384 18.6204C6.1957 21.9882 7.66947 25.1787 10.2061 27.3932L9.84615 33.0816C9.83302 33.3552 9.97417 33.6131 10.2116 33.7494C10.4491 33.8857 10.7428 33.8774 10.9723 33.728L15.8185 30.496C17.1782 30.886 18.5855 31.0849 20 31.087C27.6431 31.087 33.8461 25.5463 33.8461 18.6204C33.8461 11.6945 27.6431 6.15381 20 6.15381Z"
                      fill="#333330"/>
              </svg>
              <svg class="icon" width="40" height="40" viewBox="0 0 40 40" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M11.4534 24.1411C7.49535 19.411 5.61418 14.5286 6.28836 10.7663V10.6793V10.5923C8.31089 5.2859 14.1066 5.15541 14.9874 8.00435L16.7272 13.0933C17.0117 13.9488 16.7886 14.8917 16.1509 15.529L14.4111 17.2688C16.0146 20.7923 18.8321 23.6214 22.349 25.2393L23.8169 23.7714C24.4076 23.1782 25.2663 22.9388 26.0787 23.1407L31.9505 24.5978C34.7342 25.3916 34.93 31.1221 29.2647 33.4817C23.3711 35.1236 15.5311 29.0017 11.4534 24.1411Z"
                      fill="#333330"/>
              </svg>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col :cols="candidate.vacancy ? 6 : 4" class="pa-0">
          <div
            v-if="candidate.vacancy"
            class="candidate-vacancy mt-6"
            @click="isSharedByToken ? ()=>{} : $router.push({name: 'Vacancy', params: {vacancyId: candidate.vacancy.id}})"
          >
            <div class="candidate-vacancy_info">
              <div class="candidate-vacancy_info-text">Прикреплен:</div>
              <div class="candidate-vacancy_info-title">{{ candidate.vacancy.title }}</div>
              <CandidateFolder
                v-if="candidate.vacancy.funnel && candidate.vacancy.funnel.length"
                :candidate="candidate"/>
            </div>
            <div
              v-if="candidate.access_type !== 'read'"
              class="icon icon-close" @click.stop="$refs['confirm-remove-vacancy'].open()"></div>
          </div>
          <div v-else-if="candidate.access_type !== 'read'" class="candidate-vacancy mt-6" @click="selectVacancyToAdd">
            <div class="candidate-vacancy_info">
              <div class="candidate-vacancy_info-text">Прикрепить к вакансии</div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7">
          <div
            class="candidate-comment">
            <div class="candidate-comment_title">Комментарий
              <span
                v-if="candidate.access_type !== 'read'"
                class="color-blue" @click.stop="
              $eventBus.emit('not-main-event-modal-open', {
            candidate: candidate.id,
            vacancy: Number(candidate.vacancy?.id) || '',
            title: 'Новый комментарий',
          })" style="font-size: 13px; font-weight: normal; margin-left: 5px; cursor: pointer">Добавить</span>
            </div>
            <v-skeleton-loader v-if="loadingCandidate" type="text" height="150"/>
            <div class="candidate-comment-container"
                 v-else-if="last_comment ">
              <div class="candidate-comment_text"
                   style="cursor:pointer;"
                   :class="{'full-comment': isCommentFull}"
                   @click="candidate.access_type !== 'read' ? $eventBus.emit('not-main-event-modal-open', {id: last_comment.id}) : ()=>{}">
                <div ref="candidate_skeleton_comment" v-html="last_comment.comment"></div>
              </div>

              <div class="candidate-comment-actions">
                <span
                  v-if="skeleton_comment_height > 150"
                  class="color-blue"
                  style="margin-right: 15px; font-size: 13px; cursor: pointer"
                  @click="isCommentFull = !isCommentFull"
                >{{ isCommentFull ? 'Скрыть' : 'Подробнее' }}</span>
                <span
                  v-if="candidate.access_type !== 'read'"
                  class="color-blue"
                      style="font-size: 13px; cursor: pointer"
                      @click="$eventBus.emit('not-main-event-modal-open', {id: last_comment.id})">Редактировать</span>
              </div>
            </div>
          </div>

        </v-col>
      </v-row>
      <v-col cols="7" class="pl-0">
        <v-tabs v-model="selectedTab">
          <v-tab :ripple="false">Резюме</v-tab>
          <v-tab :ripple="false">История</v-tab>
          <v-tab v-if="candidate.access_type !== 'read'" :ripple="false">Редактировать</v-tab>
        </v-tabs>
      </v-col>
      <v-col :cols="selectedTab === 0 ? 12 : 7" class="page-body pa-0">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item>
            <ResumeTab :candidate="candidate" :resume-html="resumeHtml" @resume-loaded="loadItem"/>
          </v-tab-item>
          <v-tab-item>
            <div class="candidate-events-header">
              <v-btn v-if="candidate.access_type !== 'read'" text color="blue" depressed :ripple="false" @click="$eventBus.emit('create-event-modal-open', {candidate})" class="mr-4 pa-0">
                <v-icon size="20" class="mr-1">mdi-plus</v-icon> Собеседование
              </v-btn>
              <v-btn v-if="candidate.access_type !== 'read'" text color="blue" depressed :ripple="false" @click="$eventBus.emit('create-event-modal-open', {type: '2', candidate})" class="pa-0">
                <v-icon size="20" class="mr-1">mdi-plus</v-icon> Напоминание
              </v-btn>
            </div>
            <CandidateChainEvents v-if="candidate.events && candidate.events.length > 0" :events="candidate.events" :candidate="candidate"/>
            <NoResults v-else class="mt-8">
              <template v-slot:title>Событий нет</template>
              <template v-slot:text>
                Простота создания, быстрота наполнения, фокусировка и результаты
              </template>
            </NoResults>
          </v-tab-item>
          <v-tab-item v-if="candidate.access_type !== 'read'">
            <CandidateAboutList :candidate="candidate"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </div>
    <Confirm ref="confirm-remove-vacancy" cancel-text="Нет" accept-text="Да"
             message="Открепить от вакансии?" @accept="removeFromVacancy"/>
    <ContactsModal ref="contacts_modal" @on-click-add="selectedTab = 2"/>
    <CreateEventModal ref="create_event_modal"/>
  </div>
</template>
<script>
import CandidateAboutList from '@/views/candidate/CandidateAboutList.vue';
import CandidateChainEvents from '@/views/candidate/CandidateChainEvents.vue';
import ContactsModal from '@/views/candidate/ContactsModal.vue';
import ResumeTab from '@/components/ResumeTab';
import CreateEventModal from '@/views/event/create/CreateEventModal';
export default {
  name: 'Candidate',
  props: {
    candidateId: [String, Number],
  },
  components: {
    CreateEventModal,
    ContactsModal,
    CandidateChainEvents,
    CandidateAboutList,
    ResumeTab
  },
  data() {
    return {
      isSharedByToken: this.$route.path.split('/').includes('shr'),
      loadingCandidate: true,
      isCommentFull: false,
      skeleton_comment_height: 0,
      resumeHtml: {},

      buttonsVacancy: [
          {
          text: 'Переместить',
          icon: 'mdi-folder',
          color: '',
          handler: (item) => {
            this.$eventBus.emit('change-folder-candidates-modal-open', {
              candidateIds: [this.candidate.id],
              vacancyId: this.candidate.vacancy.id,
              folder: this.candidate.folder
            });
          },
        },
      ],
      candidate: {},
      selectedTab: 0,
      tabsName: ['resume', 'history', 'edit'],
    };
  },
  methods: {
    loadResume() {
      const inputFile = document.createElement('input');
      inputFile.setAttribute('type', 'file');
      inputFile.addEventListener('change', (e) => {
        if (e.target.files.length) {
          this.$eventBus.emit('open-loader-modal');
          this.$server.uploadFile(e.target.files[0], {
            type: 'candidate',
            parse: 1,
          })
            .then(({ response }) => {
              this.$server.request2(`candidate/update/${this.candidate.id}`, { file: response.id }, ()=>{
                this.loadItem();
                this.$eventBus.emit('close-loader-modal');
              });
            })
        }
      });
      inputFile.click();
    },
    selectVacancyToAdd() {
      this.$eventBus.emit('select-vacancies-modal-open', [this.candidate]);
    },
    removeFromVacancy() {
      const vacancyId = this.candidate.vacancy.id;
      this.$server.request2(`vacancy/removeCandidate/${vacancyId}`, { id: this.candidate.id }, () => {
        this.loadItem();
      });
    },
    noteModalOpen(noteTitle) {
      this.$eventBus.emit('candidate-note-modal-open', {
        candidate: this.candidate.id,
        vacancy: this.candidate.vacancy ? this.candidate.vacancy.id : '',
        title: noteTitle,
      });
    },
    loadItem() {
      this.$server.request2(`candidate/get/${this.candidateId}`, {}, (data) => {
        this.loadingCandidate = false;
        this.candidate = data.response;
        if (data.response.file.id) {
          this.loadResumeHtml(data.response.file.id);
        }
        this.candidate.contacts = data.response.contacts.filter((contact) => contact.value !== '' && contact.type !== '5');
        if (data.response.vacancy?.id && this.candidate.access_type !== 'read') {
          this.actionButtons.push(...this.buttonsVacancy);
        }
        setTimeout(()=>{
          if (this.$refs.candidate_skeleton_comment) this.skeleton_comment_height = this.$refs.candidate_skeleton_comment.clientHeight;
        }, 1)
      }, ()=>{
        this.loadingCandidate = false;
      });
    },
    loadResumeHtml(id) {
      this.$server.request2('files/getHtml', { id, candidate: this.candidate.id }, (data) => {
        this.resumeHtml = data.response;
      });
    },
    openWhatsApp() {
      window.open(`https://wa.clck.bar/${this.candidate.phone}`, '_blank').focus();
    },

    getCandidateByTokenFromVacancy() {
      this.$server.request2('vacancy/getCandidateByToken', {
        at: this.$route.params.token,
        candidate: this.$route.params.id,
        vacancy: this.$route.query.vacancy,
      }, (data) => {
        this.candidate = data.response;
        this.loadingCandidate = false;
      }, ()=>{
        this.loadingCandidate = false;
      });
    },
    getCandidateByToken() {
      this.$server.request2('candidate/getByToken', {
        at: this.$route.params.token,
        candidate: this.$route.params.id,
      }, (data) => {
        this.candidate = data.response;
        this.loadingCandidate = false;
      }, ()=>{
        this.loadingCandidate = false;
      });
    },
    setQueryTab(tabIndex) {
      if (!this.$route.path.split('/').includes('shr')) {
        this.$router.replace({
          path: `/candidates/candidate/${this.candidateId}`,
          query: {tab: this.tabsName[tabIndex]}}).catch(e=>{});
      } else {
        if (this.$route.query.vacancy) this.getCandidateByTokenFromVacancy()
        else this.getCandidateByToken();
      }
    }
  },
  computed: {
    last_comment() {
      if (!this.candidate.events) return null;
      let comment = this.candidate.events.find(event=>event.type === '3');
      if (comment && comment.id && comment.comment) return comment;
      else return null;
    },
    actionButtons() {
      return [
        {
          text: 'Контакты',
          icon: 'mdi-phone',
          color: '',
          handler: (item) => {
            this.$refs.contacts_modal.open(this.candidate.id, this.candidate.phone);
          },
        },
        {
          text: 'Поделиться',
          icon: 'mdi-share-variant-outline',
          color: '',
          handler: (item) => {

            this.$server.request2('candidate/createAccessToken', { ids: [this.candidate.id] }, (data) => {
              this.$eventBus.emit('open-share-candidate-modal', {
                token: data.response,
              });
            });
          },
        },
        {
          text: 'Связаться еще раз позже',
          icon: 'mdi-phone',
          color: 'blue',
          handler: (item) => this.noteModalOpen('Связаться еще раз позже'),
        },
        {
          name: 'create_interview',
          text: 'Назначить собеседование',
          icon: 'mdi-calendar',
          handler: (items) => {
            let candidate = { ...items[0] };
            this.$refs.create_event_modal.open({type: '0', candidate});
          },
        },
        {
          text: +this.candidate.favourite ? 'Убрать из избранного' : 'В избранное',
          icon: 'mdi-star',
          color: '',
          handler: (item) => {
            this.$server.request2(`candidate/update/${this.candidate.id}`, { favourite: +this.candidate.favourite ? 0 : 1 }, () => {
              this.loadItem();
            });
          },
        },
        this.candidate.access_type !== 'read' && this.candidate.vacancy && this.candidate.vacancy.id && this.candidate.not_suitable*1 === 0 ?
        {
          text: 'Не подходит',
          icon: 'mdi-close',
          color: 'red',
          handler: (item) => {
            this.$server.request2('candidate/changeFolder', {
              vacancy: this.candidate.vacancy.id,
              candidates: [this.candidate.id],
              not_suitable: 1,
            }, () => {
              this.loadItem();
            });
          },
        } : '',
      ];
    },

  },
  watch: {
    selectedTab(newTab) {
      this.setQueryTab(newTab);
    }
  },
  created() {
    this.$eventBus.on('candidate-updated, event-created-in-candidate, event-remove, event-update, comment-update, candidates-added-to-vacancy, update-candidate-in-folder, resume-update', this.loadItem);
  },
  beforeDestroy() {
    this.$eventBus.off('candidate-updated, event-created-in-candidate, event-remove, event-update, comment-update, candidates-added-to-vacancy, update-candidate-in-folder');
  },

  mounted() {
    if (!this.$route.path.split('/').includes('shr')) this.loadItem();
    const queryTab = this.$route.query.tab;
    if (queryTab) {
      this.selectedTab = this.tabsName.indexOf(queryTab);
    } else {
      this.setQueryTab(this.selectedTab);
    }
  },
};
</script>

<style lang="scss" scoped>
.page-subtitle {
  margin-top: 12px;
  &.important-contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    a, .v-btn {
      color: #333333;
      font-size: 13px;
    }
  }
}

.candidate-vacancy {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F4F3F2;
  border-radius: 6px;
  cursor: pointer;

  &_info {
    padding-left: 18px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;

    &-text {
      font-weight: 600;
      flex-shrink: 0;
      margin-right: 5px;
    }

    &-title {
      width: fit-content;
      font-size: 14px;
      margin-right: 10px;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .icon {
    padding: 16px;
    cursor: pointer;
    font-weight: 600;

    &-wrap::before {
      font-size: 38px;
    }
  }
}

.popover-container {
  align-self: flex-start;
  margin-top: 36px;
}

.candidate-actions-button {
  width: fit-content;
  height: 42px;
  display: flex;
  align-items: center;
  background: #F4F3F2;
  color: black;
}

.page-right-content {
  width: 286px;
}

.candidate-media_content {
  background: #000;
  background-size: 90%;
}

.candidate-avatar {
  position: absolute;
  width: 360px;
  z-index: 1;
  right: 0;
  .candidate-media {
    width: 100%;
    height: 210px;
    border-radius: 12px;

    &::v-deep(.no-image) {
      font-size: 38px;
    }
  }

  &_rating {
    position: absolute;
    bottom: 12px;
    left: 12px;
    padding: 7px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    cursor: pointer;
    font-weight: 700;
    font-size: 21px;
    line-height: 28px;
    letter-spacing: -.25px;
    color: var(--page-color-main);
    height: 30px;

    .candidate-rating {
      background: transparent;
      border: none;
      font-weight: bold;
      font-size: 21px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.25px;
      color: var(--page-color-main);
      margin-right: 4px;
    }

    .icon {
      font-size: 16px;
    }
  }

  &_contacts {
    position: absolute;
    bottom: 12px;
    right: 12px;
    padding: 7px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    cursor: pointer;

    .icon {
      width: 16px;
      height: 16px;
    }

    .icon:first-child {
      margin-right: 5px;
    }
  }
}

.candidate-comment {
  .text-button {
    cursor: pointer;
  }

  &_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;

    .scroll-down-icon {
      margin-left: 10px;
    }
  }

  &_text {
    font-size: 13px;
    line-height: 20px;
    max-height: 150px;
    overflow: hidden;
    &.full-comment {
      max-height: 100%;
    }
  }

  .candidate-comment-actions {
    display: flex;
  }
}
</style>
