<template>
  <div :class="['about-list', classes]">
    <ul>
      <li v-for="(item, index) in items" :key="index">
        <div
          class="about-list_item"
          :class="[item.classes, {'textarea': item.inputArea === 'textarea'}]"
          @click="itemClick(item, $event)"
        >
          <div class="about-list_item_media" v-html="item.media"></div>
          <div class="about-list_item_inner">
            <div class="about-list_item_row">
              <div class="about-list_item_title">{{ item.title }}</div>
              <div class="about-list_item_after">
                <template v-if="!item.inputArea || item.inputArea === 'after'">
                  <span
                    v-if="typeof item.value === 'object' && item.value !== null"
                    class="about-list_item_value"
                  >
                    <component
                      :is="item.value.content(this, item)"
                      v-if="typeof item.value.content === 'function'"
                      v-bind="item.value.props"
                    />
                    <component
                      :is="item.value.content"
                      v-else
                      v-bind="item.value.props"
                    />
                  </span>
                  <span
                    v-else-if="typeof item.value === 'function'"
                    class="about-list_item_value"
                    v-html="item.value(this, item)"
                  />
                  <span v-else :class="{'empty': !item.value}" class="about-list_item_value">
                    {{ item.value || item.emptyValue }}
                  </span>
                </template>
                <div v-if="item.arrow" class="icon icon-arrow flip-horizontally"></div>
              </div>
            </div>
            <div v-if="item.inputArea === 'textarea'" class="about-list_item_row">
              <div
                v-if="typeof item.value === 'object' && item.value !== null"
                class="about-list_item_textarea"
              >
                <component
                  :is="item.value.content(this, item)"
                  v-if="typeof item.value.content === 'function'"
                  v-bind="item.value.props"
                />
                <component
                  :is="item.value.content"
                  v-else
                  v-bind="item.value.props"
                />
              </div>
              <div
                v-if="typeof item.value === 'function'"
                class="about-list_item_textarea"
                v-html="item.value(this, item)"
              />
              <div v-else :class="{'empty': !item.value}" class="about-list_item_textarea">
                {{ item.value || item.emptyValue }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AboutList',
  props: {
    classes: String,
    items: Array,
  },
  methods: {
    itemClick(item, event) {
      if (typeof item.onClick === 'function') item.onClick(this, event);
    },
  },
};
</script>

<style lang="scss">
.about-list {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &_item {
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid var(--border-color);
    max-width: 580px;
    &_media {
      display: flex;
      justify-content: center;
      padding: 20px 12px 20px 0;

      .icon {
        font-size: 24px;
        color: rgba(var(--page-color-main-rgb), .25);
      }
    }

    &_inner {
      width: 100%;
      min-height: 64px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > * {
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_after {
      display: flex;
      align-items: center;

      .icon.icon-arrow {
        margin-left: 8px;
        font-size: 16px;
        color: rgba(var(--page-color-main-rgb), .25);
      }
    }

    &_title {
      font-size: 14px;
      line-height: 20px;
      color: rgba(var(--page-color-main-rgb), .5);
    }

    &_value {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: block;
      max-width: 350px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.empty {
        color: rgba(var(--page-color-main-rgb), .4);
      }
    }

    &_textarea {
      font-size: 13px;
      line-height: 17px;

      &.empty {
        color: rgba(var(--page-color-main-rgb), .4);
      }
    }

    &.pointer {
      cursor: pointer;
    }

    &.textarea {
      .about-list_item_inner {
        padding: 20px 0;
      }
    }
  }
}
</style>
