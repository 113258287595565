<template>
  <div class="candidate-about">
    <AboutList :items="itemsWithHandlers"/>
    <!--    <div class="remove-button" @click="$refs['remove-candidate-confirm'].open">Удалить</div>-->
    <Confirm ref="remove-candidate-confirm" message="Удалить кандидата?" @accept="removeCandidate"/>
    <ModalWithFields ref="edit-modal" @submit="updateCandidate"/>
    <ModalWithFields ref="contacts-edit-modal" :add-buttons="editContact.addButtons" @submit="changeContact"/>
    <ModalWithFields
      ref="contacts-create-modal"
      @submit="createContact"
    />
  </div>
</template>

<script>
import AboutList from '@/components/AboutList.vue';
import ModalWithFields from '@/components/ModalWithFields';
export default {
  name: 'CandidateAboutList',
  components: { AboutList, ModalWithFields },
  props: ['candidate'],
  data() {
    return {
      modalFields: [],
      editContact: {
        id: null,
        fields: [],
        addButtons: [
          {
            text: 'Удалить',
            classes: 'remove-button',
            onClick: () => {
              this.$server.request(`candidate/removeContact/${this.$route.params.candidateId}`, { id: this.editContact.id })
                .then(() => {
                  this.updateCandidate();
                  this.$refs['contacts-edit-modal'].close();
                });
            },
          },
        ],
      },
      createContactFields: [
        {
          title: 'Тип контакта',
          type: 'select',
          name: 'type',
          placeholder: 'Не указан',
          values: Object.entries(this.$store.state.static.candidateContactsStatuses)
            .map(([value, text]) => ({
              value,
              text,
            })),
        },
        {
          title: 'Номер или ссылка',
          name: 'value',
          placeholder: 'Например, vk.com/ivan_ivanov',
        },
      ],
    };
  },
  methods: {
    updateCandidate(formData) {
      let toResponse = {...formData};
      if (Object.keys(formData).includes('birthday')) {
        toResponse.birthday = toResponse.birthday.split('.').reverse().join('-');
      }
      this.$server.request2(`candidate/update/${this.candidate.id}`, toResponse, () => {
        this.$eventBus.emit('candidate-updated');
      });
    },
    changePhoto() {
      const inputFile = document.createElement('input');
      inputFile.setAttribute('type', 'file');
      inputFile.setAttribute('accept', 'image/*');
      inputFile.addEventListener('change', (e) => {
        if (e.target.files.length) {
          this.$server.uploadFile(e.target.files[0])
            .then((dataResponse) => {
              this.updateCandidate({ image: dataResponse.response.id });
            });
        }
      });
      inputFile.click();
    },
    removeCandidate() {
      this.$server.request(`candidate/remove/${this.$route.params.candidateId}`)
        .then(() => this.$store.dispatch('removeCandidate', id))
        .then(() => this.$eventBus.emit('candidate-remove'));
      this.$router.replace('/candidates/');
    },
    changeContact(formData) {
      const params = {
        id: this.editContact.id,
        ...formData,
      };
      this.$server.request2(`candidate/updateContact/${this.$route.params.candidateId}`, params, (data) => {
        this.$eventBus.emit('candidate-updated');
      });
    },
    createContact(formData) {
      formData.type = this.editContact.type;
      this.updateCandidate({
        contacts: [
          ...this.candidate.contacts,
          formData,
        ],
      });
    },
  },
  computed: {
    contacts() {
      const contacts = this.candidate.contacts || [];
      return contacts.map((contact) => ({
        title: this.$store.state.static.candidateContactsStatuses[contact.type],
        value: contact.type*1 === 0 ? this.convertPhoneStr(contact.value) : contact.value,
        onClick: (item) => {
          this.editContact.id = contact.id;
          this.editContact.fields = [{
            title: this.$store.state.static.candidateContactsStatuses[contact.type],
            name: 'value',
            value: contact.value,
          }];
          if (contact.type*1 === 0) {
            this.editContact.fields[0].mask = ['+# (###) ###-##-##', '+### (##) ###-##-##'];
            this.editContact.fields[0].value = this.convertPhoneStr(contact.value);
          }
          this.$refs['contacts-edit-modal'].open(this.editContact.fields);
        },
      }));
    },
    items() {
      return [
        {
          media: '<div class="icon icon-photos"></div>',
          title: 'Фото',
          emptyValue: 'Изменить',
          onClick: (item, event) => {
            this.changePhoto();
          },
        },
        {
          media: '<div class="icon icon-info"></div>',
          title: 'Фамилия',
          value: this.candidate.surname,
          emptyValue: 'Не указана',
          fields: [{
            title: 'Фамилия',
            name: 'surname',
            placeholder: 'Не указан',
            value: this.candidate.surname,
          }],
        },
        {
          media: '<div class="icon icon-info"></div>',
          title: 'Имя',
          value: this.candidate.name,
          emptyValue: 'Не указано',
          fields: [{
            title: 'Имя',
            name: 'name',
            placeholder: 'Не указано',
            value: this.candidate.name,
          }],
        },
        {
          media: '<div class="icon icon-profile"></div>',
          title: 'Пол',
          value: (() => {
            if (Number(this.candidate.gender) === 1) return 'Мужской';
            if (Number(this.candidate.gender) === 2) return 'Женский';
            return '';
          })(),
          emptyValue: 'Не указан',
          fields: [{
            title: 'Пол',
            name: 'gender',
            value: this.candidate.gender,
            type: 'select',
            values: '0:Не указан;1:Мужской;2:Женский',
          }],
        },
        {
          media: '<div class="icon icon-calendar"></div>',
          title: 'Дата рождения',
          value: this.formatDate(this.candidate.birthday, '{dd}.{mm}.{yyyy}'),
          emptyValue: 'Не указана',
          fields: [{
            title: 'Дата рождения',
            name: 'birthday',
            placeholder: 'ДД.ММ.ГГГГ',
            value: this.formatDate(this.candidate.birthday, '{dd}.{mm}.{yyyy}'),
            mask: '##.##.####'
          }],
        },
        {
          media: '<div class="icon icon-phone"></div>',
          title: 'Телефон',
          value: this.convertPhoneStr(this.candidate.phone),
          emptyValue: 'Не указан',
          fields: [{
            title: 'Телефон',
            name: 'phone',
            value: this.convertPhoneStr(this.candidate.phone),
            mask: ['+# (###) ###-##-##', '+### (##) ###-##-##'],
            placeholder: 'Не указан',
            type: 'phone',
          }],
        },
        {
          media: '<div class="icon icon-envelope"></div>',
          title: 'Email',
          value: this.candidate.email,
          fields: [{
            title: 'Email',
            name: 'email',
            value: this.candidate.email,
            placeholder: 'Не указан',
          }],
        },
        {
          media: '<div class="icon icon-tag"></div>',
          title: 'Должность',
          value: this.candidate.position,
          emptyValue: 'Не указана',
          fields: [{
            title: 'Должность',
            name: 'position',
            placeholder: 'Не указана',
            value: this.candidate.position,
          }],
        },
        {
          media: '<div class="icon icon-plus"></div>',
          title: 'Добавить контакт',
          onClick: () => {
            this.$refs['contacts-create-modal'].open(this.createContactFields);
          },
        },
        ...this.contacts,
      ];
    },
    itemsWithHandlers() {
      return Array.from(this.items, (item) => ({
        arrow: true,
        classes: 'pointer',
        onClick: () => {
          this.$refs['edit-modal'].open(item.fields);
        },
        ...item,
      }));
    },
  },
  created() {
    this.$eventBus.on('edit-phone-modal-open', () => {
      this.$refs['edit-modal'].open(this.items.find(item => item.fields && item.fields[0].name === 'phone').fields);
    });
  },
  beforeDestroy() {
    this.$eventBus.off('edit-phone-modal-open');
  }
};
</script>

<style lang="scss">
.candidate-media {
  width: 40px;
  height: 40px;
}
</style>
